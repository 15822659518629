<template>
<div class="content-wrapper">
  <bo-page-header></bo-page-header>
  <div class="content pt-0">
    <b-row>
      <b-col md="6" lg="4">
        <b-card>
          <div class="total_text_crm">
            <h3>383</h3>
            <p>Submitted Lead</p>
          </div>
        </b-card>
      </b-col>
      <b-col md="6" lg="4">
        <b-card>
          <div class="total_text_crm">
            <h3>383</h3>
            <p>Followed Up</p>
          </div>
        </b-card>
      </b-col>
      <b-col md="6" lg="4">
        <b-card>
          <div class="total_text_crm">
            <h3>383</h3>
            <p>Closed Lead</p>
          </div>
        </b-card>
      </b-col>
      <b-col lg="3" md="6">
        <b-card>
          <div class="total_text_crm ">
            <h3 class="text-danger">10</h3>
            <p>Hot</p>
          </div>
        </b-card>
      </b-col>
      <b-col lg="3" md="6">
        <b-card>
          <div class="total_text_crm">
            <h3 class="text-green">383</h3>
            <p>Medium</p>
          </div>
        </b-card>
      </b-col>
      <b-col lg="3" md="6">
        <b-card>
          <div class="total_text_crm">
            <h3 class="text-info">383</h3>
            <p>Cold</p>
          </div>
        </b-card>
      </b-col>
      <b-col lg="3" md="6">
        <b-card>
          <div class="total_text_crm">
            <h3 class="text-green">383</h3>
            <p>Default</p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      class="mb-0"
      body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap"
    >
      <div class="d-flex align-items-center mb-3 mb-md-0">
        <div>
          <h5 class="font-weight-bold mb-0">Submitted Lead </h5>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3 mb-md-0">
        <ul class="nav nav-pills justify-content-end mb-0">
          <li class="nav-item"><a href="#right-pill1" class="nav-link bg-indigo-400 active"
              data-toggle="tab">Days</a></li>
          <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Week</a></li>
          <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Month</a></li>
        </ul>
        <div class="ml-3">
          <div class="input-group">
            <span class="input-group-prepend">
              <span class="input-group-text"><i class="icon-calendar22"></i></span>
            </span>
            <date-range-picker
              class="form-control"
              control-container-class=""
              opens="left"
              append-to-body
              :date-range="dateRange"
              style="min-width: 180px"
            />
          </div>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="chart-container">
        <v-chart class="chart has-fixed-height" :option="submittedLeadOptions" />
      </div>
    </b-card>
    <b-card no-body>
      <template #header>
        <b-card-title title-tag="h6" class="font-weight-bold">Latest Closing</b-card-title>
      </template>
      <div class="table-responsive">
        <table class="table table-bordered  text-nowrap">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Submitted Date</th>
              <th>Closed Date</th>
              <th>Mobile Phone</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td>1</td>
              <td><span class="font-weight-bold mb-0">Ibu Mega Murdiana</span></td>
              <td> 05-06-2020</td>
              <td> 05-07-2020</td>
              <td> +62 8767 9897 7383</td>
              <td>Closing</td>
            </tr>

            <tr>
              <td>2</td>
              <td><span class="font-weight-bold mb-0">Ibu Yosi Yuniar</span></td>
              <td> 05-06-2020</td>
              <td> 05-07-2020</td>
              <td> +62 8767 9897 7383</td>
              <td>Closing </td>
            </tr>

            <tr>
              <td>3</td>
              <td><span class="font-weight-bold mb-0">Bpk Fauzi</span></td>
              <td> 05-06-2020</td>
              <td> 05-07-2020</td>
              <td> +62 8767 9897 7383</td>
              <td> Closing</td>
            </tr>

            <tr>
              <td>4</td>
              <td><span class="font-weight-bold mb-0">Bpk Fajar</span></td>
              <td> 05-06-2020</td>
              <td> 05-07-2020</td>
              <td> +62 8767 9897 7383</td>
              <td>Closing</td>
            </tr>

            <tr>
              <td>5</td>
              <td><span class="font-weight-bold mb-0">Ibu Anggraeni</span></td>
              <td> 05-06-2020</td>
              <td> 05-07-2020</td>
              <td> +62 8767 9897 7383</td>
              <td>Closing</td>
            </tr>

            <tr>
              <td>6</td>
              <td><span class="font-weight-bold mb-0">Ibu Anggraeni</span></td>
              <td> 05-06-2020</td>
              <td> 05-07-2020</td>
              <td> +62 8767 9897 7383</td>
              <td>Closing</td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #footer>
        <ul class="pagination pagination-flat align-self-center">
          <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
          <li class="page-item active"><a href="#" class="page-link">1</a></li>
          <li class="page-item"><a href="#" class="page-link">2</a></li>
          <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
          <li class="page-item"><a href="#" class="page-link">4</a></li>
          <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
        </ul>
      </template>
    </b-card>
    <b-card no-body>
      <template #header>
        <b-card-title class="font-weight-bold" title-tag="h6">Today's Activities</b-card-title>
      </template>
      <div class="table-responsive">
        <table class="table table-bordered table-striped text-nowrap">
          <thead>
            <tr>
              <th>#</th>
              <th>Task Name</th>
              <th>Responsible</th>
              <th>Participant</th>
              <th>Start End Time</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>1</td>
              <td><span class="font-weight-bold mb-0">Meeting</span></td>
              <td> 05-06-2020</td>
              <td> 05-07-2020</td>
              <td> +62 8767 9897 7383</td>
              <td>Closing</td>
            </tr>
            <tr>
              <td>2</td>
              <td><span class="font-weight-bold mb-0">Call Customer</span></td>
              <td> 05-06-2020</td>
              <td> 05-07-2020</td>
              <td> +62 8767 9897 7383</td>
              <td>Closing</td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #footer>
        <ul class="pagination pagination-flat align-self-center">
          <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
          <li class="page-item active"><a href="#" class="page-link">1</a></li>
          <li class="page-item"><a href="#" class="page-link">2</a></li>
          <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
          <li class="page-item"><a href="#" class="page-link">4</a></li>
          <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
        </ul>
      </template>
    </b-card>
  </div>
  <bo-footer></bo-footer>
</div>
</template>
<script>
import chartDatas from '@/dummies/chartDatas'
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  LineChart,
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart from 'vue-echarts'
import GlobalVue from '../../libs/Global.vue';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
])

export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return {
      dateRange: {},
      submittedLeadOptions: chartDatas.submittedLeadOptions
    }
  }
}
</script>
